import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb,faXmark, faBars } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate, NavLink } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  // const location = useLocation();

  const openMenu = () => {
    const menu = document.getElementById('hamburger-menu');
    menu.style.visibility = "visible";
  
    const scrim = document.getElementById('scrim');
    scrim.style.visibility = "visible";
  }
  
  const closeMenu = () => {
    const menu = document.getElementById('hamburger-menu');
    menu.style.visibility = "hidden";
  
    const scrim = document.getElementById('scrim');
    scrim.style.visibility = "hidden";
  }

  return (
    <nav id="navbar">
      <div id='scrim' onClick={closeMenu}></div>
      <div>
        <button className='icon-button' onClick={openMenu} aria-label='Menu Button'>
          <FontAwesomeIcon icon={faBars} />
        </button>
        <div id='hamburger-menu'>
          <div id="close-block">
            <button aria-label='Close Menu Button' id="close-button" className='icon-button' onClick={closeMenu}>
              <FontAwesomeIcon icon={faBars}/>
            </button>
          </div>
          <ul>
            <li><NavLink onClick={closeMenu} to={"/"}>Home</NavLink></li>
            <li><NavLink onClick={closeMenu} to={"/products"}>Products</NavLink></li>
            <li><NavLink onClick={closeMenu} to={"/locations"}>Locations</NavLink></li>
          </ul>
        </div>
      </div>
      <div id="logo" onClick={() => navigate('/')}>
        <h1>NEW
        <div className='icon'>
          <FontAwesomeIcon icon={faLightbulb}/>
        </div>
        STAR</h1>
        <p>LED LIGHTING</p>
      </div>
    </nav>
  )
}
export default Navbar
