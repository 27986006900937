import { useEffect, useState } from "react";
import { useParams, NavLink } from "react-router-dom";

const ProductDetails = () => {
  const [productData, setProductData] = useState({});
  const [cleanCategoryName, setCleanCategoryName] = useState("");
  const { category, name } = useParams();

  useEffect(() => {
    fetch('/assets/entries.json').then(response => {
      return response.json()
    }).then(data => {
      setProductData(data[category].find(entry => entry.model === name));
    });

    setCleanCategoryName(category.replaceAll("-"," ").split(" ").map(word => word[0].toUpperCase()+word.substring(1)).join(" "));
  },[])

  return (
    <section className="flex-column">
      <ul className="breadcrumb flex">
        <li><NavLink to={"/products"}>Products</NavLink></li>
        <li><NavLink to={`/products/${category}`}>{cleanCategoryName}</NavLink></li>
        <li><span className="current-page">{productData.model}</span></li>
      </ul>
      
      <h1>{productData.model}</h1>
      <h2>{productData.description}</h2>
      <div>
        {Object.keys(productData).length === 3 ? <></> : <h3>Specifications</h3>}
        {productData.cert ? <>
        <p><span>Certifications: </span>{productData.cert}</p>
        </> : <></>}
        {productData.color_temp ? <>
        <p><span>Color Temperature: </span>{productData.color_temp}</p>
        </> : <></>}
        {productData.lumens ? <>
        <p><span>Lumens: </span>{productData.lumens}</p>
        </> : <></>}
        {productData.voltage ? <><p><span>Voltage(V): </span>{productData.voltage}</p></> : <></>}
        {productData.wattage ? <>
        <p><span>Wattage(W): </span>{productData.wattage}</p></> : <></>}
      </div>
    </section>
  )
}
export default ProductDetails