import hero_image from '../images/pexels-fotoaibe-1571453.jpg';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <main>
        <div className="hero">
          <img src={hero_image} alt="Image of a show room"/>
          <button onClick={() => navigate('/locations')}>Locations</button>
        </div>
        <section>
          
          <div id='updates'>
            <h1>Welcome to New Star LED Lighting</h1>
            <div className='update-entry'>
              <h2>Grand Opening at Freeport, NY</h2>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default Home