import { useEffect, useState } from "react";
import { useParams, NavLink } from "react-router-dom";
import ProductCard from "../components/ProductCard";

const Category = () => {
  const [products, setProducts] = useState([]);
  const [cleanCategoryName, setCleanCategoryName] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const { category } = useParams();

  useEffect(() => {
    fetch('/assets/entries.json').then(response => {
      return response.json()
    }).then(data => {
      let subcategory = data[category];
      setProducts(subcategory)
      let subDuplicate = subcategory.map(e => e.category);
      let sub = [...new Set(subDuplicate)];
      setSubcategories(sub);
    });

    setCleanCategoryName(category.replaceAll("-"," ").split(" ").map(word => word[0].toUpperCase()+word.substring(1)).join(" "));
  }, [])

  return (
    <section className="flex-column">
      <ul className="breadcrumb flex">
        <li><NavLink to={"/products"}>Products</NavLink></li>
        <li><span className="current-page">{cleanCategoryName}</span></li>
      </ul>
      {subcategories.map((subcategory, subcategory_index) => (
        <div key={subcategory_index}>
          <h1>{subcategory}</h1>
          <div className="flex flex-wrap">
            {products.filter(product => product.category === subcategory).map((product, product_index) => (
              <ProductCard key={product_index} product={product} category={category} />
            ))}
          </div>
        </div>
      ))}
    </section>
  )
}
export default Category