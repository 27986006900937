
const Locations = () => {
  return (
    <>
      <main>
        <section 
        className="flex flex-center"
        >
          <div className='container card'>
            <div className='store-info'>
              <h2>Freeport, NY</h2> <br />
              <p>
                917-289-0015
              </p>
              <br />
              <p>
                34 East Sunrise Highway
              </p>
              <p>
                Freeport, NY 11520
              </p>
              <br />
              <div>
                <h3>Business Hours</h3>
                <p>Monday-Friday <span>9AM-5PM</span></p>
                <p>Saturday <span>11AM-4PM</span></p>
                <p>Sunday <span>Closed</span></p>
              </div>
            </div>
          </div>
          <div className='container card'>
            <div className='store-info'>
              <h2>College Point, NY</h2> <br />
              <p>
                347-732-4759
              </p>
              <br />
              <p>
                132-08 11th Avenue
              </p>
              <p>
                College Point, NY 11356
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default Locations