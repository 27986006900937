import { useNavigate, NavLink } from 'react-router-dom';

const Products = () => {
  const navigate = useNavigate();

  return (
    <>
      <main>
        <section className="flex-column">
          <ul className="breadcrumb flex">
            <li><span className="current-page">Products</span></li>
          </ul>
          <div id="category-card-container" className="flex flex-wrap flex-center">
            <button className="category-card box-shadow" onClick={() => navigate("/products/indoor-lights")}>
              Indoor Lights
            </button>
            <button className="category-card box-shadow" onClick={() => navigate("/products/outdoor-lights")}>
              Outdoor Lights
            </button>
            <button className="category-card box-shadow" onClick={() => navigate("/products/light-controls")}>
              Light Controls
            </button>
            <button className="category-card box-shadow" onClick={() => navigate("/products/outlets")}>
              Outlets
            </button>
            <button className="category-card box-shadow" onClick={() => navigate("/products/wall-plates")}>
              Wall Plates
            </button>
            <button className="category-card box-shadow" onClick={() => navigate("/products/accessories")}>
              Accessories
            </button>
          </div>
        </section>
      </main>
    </>
  )
}
export default Products