import { useNavigate } from "react-router-dom";

const ProductCard = ({product, category}) => {
  const navigate = useNavigate();

  return (
  <div className="product-card" onClick={() => navigate(`/products/${category}/${product.model}`)}>
    <h2>{product.model}</h2>
    <div>{product.description}</div>
  </div>
  )
}
export default ProductCard